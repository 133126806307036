import React, { useState, useEffect } from "react"
import styled from "styled-components"
import mapMarker from "../../Images/icons/maps-line.svg"
import Fade from "react-reveal/Fade"
import { Link } from "gatsby"
import noPhoto from "../../Images/icons/no-photo.svg"
import { sendRequest } from "../../services/requestTool"
import DefaultLoaderComponent from "./DefaultLoaderComponent"
import moment from "moment"
import MainButtonComponent from "./MainButtonComponent"

const SingleSalonWrapper = styled.div`
  border: 1px solid rgba(133, 133, 133, 0.7);
  margin: 10px;
  padding: 5px;
`
const SingleSalonContent = styled.div`
  display: flex;
  flex-direction: column;
`
const SalonImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

const SalonImage = styled.img`
  width: 100%;
  margin: 0;
  max-height: 100%;
  object-fit: contain;
`
const SalonDescription = styled.div``

const SalonLocation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`

const MapMarkerImage = styled.img`
  width: 15px;
  margin: 5px;
`
const SalonAddress = styled.div`
  font-size: 16px;
`

const SalonHours = styled.div``
const SalonHoursTitle = styled.h5`
  margin: 5px 0;
  text-align: center;
`
const SalonHoursContent = styled.div``
const OpenHoursItem = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: Center;
`
const OpenHoursDay = styled.div`
  font-weight: bold;
  margin-right: 5px;
`
const OpenHoursDayValue = styled.div`
  text-align: center;
`

const SalonBookingButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`
const SalonBookingButton = styled.button`
  border: 3px solid #000;
  background-color: #fff;
  padding: 5px 20px;
  outline: none;
  transition: all 0.4s ease;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #000;
  }
`

export default function SingleObjectComponent({
  salon,
  clickCallback,
  buttonText,
  imageWidth,
  imageHeight,
  active,
}) {
  const [salonImage, setSalonImage] = useState(null)
  const [salonWrapperWidth, setSalonWrapperWidth] = useState(300)
  useEffect(() => {
    if (salon.fileIds == null || salon.fileIds.length == 0) {
      setSalonImage(noPhoto)
      return
    }
    const fileId = salon.fileIds[0]
    sendRequest({
      url: `https://spoomyfly.com/api/File/GetFile?FileID=${fileId}`,
      responseType: "blob",
    })
      .then(res => {
        typeof window !== "undefined" &&
          setSalonImage(window.URL.createObjectURL(new Blob([res.data])))
      })
      .catch(() => {
        setSalonImage(noPhoto)
      })
  }, [])
  return (
    <SingleSalonWrapper
      style={{
        width: `${
          imageWidth > salonWrapperWidth ? imageWidth : salonWrapperWidth
        }px`,
      }}
    >
      <Fade bottom duration={1000} delay={100}>
        <SingleSalonContent>
          <SalonImageWrapper
            style={{
              width: `${imageWidth ? imageWidth : 100}px`,
              height: `${imageHeight ? imageHeight : 100}px`,
            }}
          >
            {salonImage ? (
              <SalonImage src={salonImage} />
            ) : (
              <DefaultLoaderComponent />
            )}
          </SalonImageWrapper>
          {/* <SalonImage src={require(`../../Images/${salon.photo}`)} /> */}
          <SalonDescription>
            <SalonLocation>
              <MapMarkerImage src={mapMarker}></MapMarkerImage>
              <SalonAddress>{salon.address}</SalonAddress>
            </SalonLocation>
            <SalonHours>
              <SalonHoursTitle>Godziny otwarcia:</SalonHoursTitle>
              {/* divided by week day */}
              {/* <SalonHoursContent>
                {salon.openHours.map((h, hIndex) => {
                  return (
                    <OpenHoursItem key={hIndex}>
                      <OpenHoursDay>{h.days}</OpenHoursDay>
                      <OpenHoursDayValue>{h.value}</OpenHoursDayValue>
                    </OpenHoursItem>
                  )
                })}
              </SalonHoursContent> */}
              {/* single open value */}
              <OpenHoursDayValue>
                {moment()
                  .set("h", salon.openingHours.hours)
                  .set("m", salon.openingHours.minutes)
                  .format("HH:mm")}{" "}
                -{" "}
                {moment()
                  .set("h", salon.closingHours.hours)
                  .set("m", salon.closingHours.minutes)
                  .format("HH:mm")}
              </OpenHoursDayValue>
              {/*  */}
            </SalonHours>
          </SalonDescription>
          <SalonBookingButtonWrapper>
            <MainButtonComponent
              text={buttonText}
              onClick={clickCallback}
              borderThickness={2}
              fontSize={"14px"}
              padding={"5px 15px"}
              active={active}
            ></MainButtonComponent>
          </SalonBookingButtonWrapper>
        </SingleSalonContent>
      </Fade>
    </SingleSalonWrapper>
  )
}
