import React from "react"
import styled, { css } from "styled-components"

const buttonActiveStyle = css`
  background-color: #000;
  color: #fff;
`
const MainButton = styled.button`
  background-color: rgba(0, 0, 0, 0);
  font-weight: bold;
  outline: none;
  cursor: pointer;
  transition: all 0.4s ease-out;
  &:hover,
  &.active {
    ${buttonActiveStyle}
  }
`
export default function MainButtonComponent({
  text,
  onClick,
  padding,
  fontSize,
  borderThickness,
  margin,
  width,
  active,
}) {
  return (
    <MainButton
      style={{
        width: width || "auto",
        margin: margin || "10px",
        padding: padding || "10px 40px",
        fontSize: fontSize || "20px",
        border: `${borderThickness ? borderThickness : 4}px solid #000`,
      }}
      className={active ? "active" : ""}
      onClick={onClick}
    >
      {text}
    </MainButton>
  )
}
